
import {MICRO_APP_INSTANCE} from '@/config/index';
import { TCurrentUser } from './user';
import { TMediaModel } from './media';
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: Function;
    [MICRO_APP_INSTANCE]:any
  }
}

export interface TGlobalState {
  currentUser: TCurrentUser;
  currentNetworkId: number;
  currentCampaignId: number;
  medias?:TMediaModel[]
  setCurrentNetworkId?:any
}

export enum TActionTypeEnum {
  SET_NETWORK_ID = 'setNetworkId',
  SET_CAMPAIGN_ID = 'setCampaignId',
  SET_USER = 'setUser',
  SET_BY_QIANKUN = 'setByQiankun',
  SET_MEDIAS = 'setMedias'
}

interface TSetNetworkIdAction {
  type: typeof TActionTypeEnum.SET_NETWORK_ID;
  payload: number | string | null | undefined;
}

interface TSetCampaignIdAction {
  type: typeof TActionTypeEnum.SET_CAMPAIGN_ID;
  payload: number | string | null | undefined;
}

interface TSetCurrentUserAction {
  type: TActionTypeEnum.SET_USER;
  payload: TCurrentUser;
}

interface TSetByQiankunAction {
  type: TActionTypeEnum.SET_BY_QIANKUN;
  payload: TGlobalState;
}

interface TSetMediasAction {
  type: TActionTypeEnum.SET_MEDIAS;
  payload:  TMediaModel[];
}

export type TAction =
  | TSetNetworkIdAction
  | TSetCampaignIdAction
  | TSetCurrentUserAction
  | TSetByQiankunAction
  | TSetMediasAction

