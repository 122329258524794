/**
 * 微应用注册
 */

import { registerMicroApps, start } from 'qiankun';
import { initGlobalState, MicroAppStateActions } from 'qiankun';
import { TGlobalState } from '@/types/global';
const IS_DEV = process.env.NODE_ENV === 'development';
const SUB_APP_CONTAINER = "subappView";
interface AppdefinType {
  name: string,
  entry: string,
  container: string | HTMLElement,
  activeRule: string | ((location: Location) => boolean) | Array<string | ((location: Location) => boolean)>
  props?: {
    [prototype: string]: string
  }
}
class MicroApp {
  host: string = '';
  state: any;
  actions: MicroAppStateActions;
  appList: AppdefinType[];

  constructor({ host, state }: { state: TGlobalState, host: string }) {
    this.host = host;
    this.state = state;
    this.actions = initGlobalState(this.state);
    this.appList = this.getAppList();
  }

  private getEntry(name: string, port: number) {
    return IS_DEV ? `//localhost:${port}/` : `//${this.host}/sub-app/${name}/`;
  }
  //定义微应用列表
  private getAppList() {
    return [
      {
        name: 'serving-v1', // 子应用的名称
        entry: this.getEntry('serving-v1', 3001),//定义子应用的入口及端口
        activeRule: '/serving',//定义子应用启动的条件
        container: `#${SUB_APP_CONTAINER}`, // 子应用挂载的div
        props: {
          routerBase: '/serving',
        },
      },
      {
        name: 'serving-v2', // 子应用的名称
        entry: this.getEntry('serving-v2', 9001),//定义子应用的入口及端口
        activeRule: '/serving2',//定义子应用启动的条件
        container: `#${SUB_APP_CONTAINER}`, // 子应用挂载的div
        props: {
          routerBase: '/serving2',
        },
      }
    ];
  }

  //注册微应用
  register() {
    registerMicroApps(
      this.appList.map((app) => ({
        ...app,
        props: {
          ...app.props,
          state: this.state,
        },
      })),
      {
        beforeLoad: async (app) => {
          console.log('before load app.name====>>>>>', app.name);
        },
        beforeMount: [
          async (app) => {
            console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name);
          },
        ],
        afterMount: [
          async (app) => {
            console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name);
          },
        ],
        afterUnmount: [
          async (app) => {
            // 清除挂载到body下面的 dom, 比如旧系统的 modal/日历 等插件
            [...Array.from(document.body.children)].forEach((child) => {
              if (child.id === 'master-root') return;
              if (child.tagName === 'SCRIPT') return;
              document.body.removeChild(child);

            });
            console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name);
          },
        ],
      },
    );
  }

  // 启动
  start() {
    start({ singular: false });
  }
}

export default MicroApp;
