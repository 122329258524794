/*
 * @Description: 
 * @Author: jiangdongdong@mininglamp.com
 * @Date: 2022-03-15 17:14:39
 */
/**
 * 全局菜单栏
 */
import React, { useMemo, useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { RouterType, LazyRouterType, reportRouters } from '@/routers'
import useGlobalState from '@/hooks/useGlobalState';
import { hasPermission } from '@/utils/utils';
import {embedChatbot} from '@/utils/embed.min.js';
import './style.less';


const MainMenu = ({ routes }: any) => {
  const { pathname } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [menus, setMenus] = useState(routes.concat(reportRouters));
  const { currentUser, currentNetworkId } = useGlobalState();
  //设置选中项目
  const getSelectedKeys = useMemo(() => {
    const list = pathname.split('/').splice(1);
    const returnList = list.map((item, index) => `/${list.slice(0, index + 1).join('/')}`);
    if (returnList[0] === '/' || returnList[0] === '/serving') {
      return ['/serving2']
    }
    if (returnList[0] === '/report') {
      return ['/report/optimization']
    }
    return [pathname];
  }, [pathname]);

  //点击切换tab
  const handleClick = (e: any) => {
    e.domEvent.stopPropagation()
    const targetMenu = menus.find((menu: LazyRouterType) => e.key === menu.path);
    if (!targetMenu) return;
    if (targetMenu.newTab) {
      window.open(targetMenu.path, '_blank');
    } else {
      window.history.replaceState(null, '', e.key);
    }
  };
  const renderMenuItem = (target: RouterType) => {

    const menu = (
      <Menu
        onClick={handleClick}
      >
        {reportRouters.map((subMenu: RouterType) => (
          subMenu.privilege && hasPermission(subMenu.privilege, 'r', currentUser, currentNetworkId) && <Menu.Item key={subMenu.path} data-new-tab={subMenu.newTab}>
            {subMenu.title}
          </Menu.Item>
        ))}
      </Menu>
    )
    const reportRouter = reportRouters.filter((subMenu: RouterType) => {
      return subMenu.privilege && hasPermission(subMenu.privilege, 'r', currentUser, currentNetworkId)
    })
    return target
      .filter((item: LazyRouterType) => item.path && item.title)
      .map((subMenu: LazyRouterType) => (
        subMenu.key === 'report' ?
          !!reportRouter.length && hasPermission('report', 'r', currentUser, currentNetworkId) && <Menu.Item key={reportRouter[0].path} data-new-tab={subMenu.newTab}>
            <Dropdown key={subMenu.path} overlay={menu} overlayClassName='title-nav-dropDown' placement="bottomLeft" arrow>
              <div>
                {subMenu.title}
              </div>
            </Dropdown>
          </Menu.Item>
          : subMenu.privilege && hasPermission(subMenu.privilege, 'r', currentUser, currentNetworkId) && <Menu.Item key={subMenu.path} data-new-tab={subMenu.newTab}>
            {subMenu.title}
          </Menu.Item>
      ))
  }
  useEffect(()=>{
    embedChatbot()
  })
  return (
    <Menu
      mode="horizontal"
      className="header-menu"
      selectedKeys={getSelectedKeys}
      onClick={handleClick}
    >
      {renderMenuItem(routes)}
    </Menu>
  );
};

export default MainMenu;
