/**
 * enum 工具函数
 * https://github.com/tranvansang/enum-for#readme
 */

/**
 * 获取enum类型的key
 * @param enumType
 * @returns keys of enum
 */
 export const getEnumKeys = (enumType: any) =>
 Object.keys(enumType).filter((key) => Number.isNaN(Number(key)));

/**
* 获取enum类型的value
* @param enumType enum
* @returns values of enum
*/
export const getEnumValues = (enumType: any) =>
 getEnumKeys(enumType).map((key: string) => enumType[key]);

/**
* 获取enum类型的键值对
* @param enumType enum
* @returns [[key, value], [key, value]]
*/
export const getEnumEntries = (enumType: any) =>
 getEnumKeys(enumType).map((key: string) => [key, enumType[key]]);
