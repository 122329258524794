import { CampaignCom,ReportCom } from '@/pages';
import BasicLayout from '@/layout';
export interface LazyRouterType {
  key:string,
  path: string,
  component?: React.LazyExoticComponent<any>,
  title: string,
  exact?: boolean,
  redirect?: string,
  newTab?:boolean,
  privilege?:string,
}
export interface RouterType {
  path: string,
  component?: React.ComponentType<any> ,
  title: string,
  exact?: boolean,
  redirect?: string,
  childRoutes?:  LazyRouterType[],
  privilege?:string,
  [prototype:string]:any
}

const Routers: RouterType[] = ([
  {
    path: '/',
    title:'基本布局',
    component: BasicLayout,//基本布局
    childRoutes: [
      {
        key:"",
        path: '/',
        title: '',
        exact:true,
        redirect:"/serving2",
      },
      {
        key:"campaign",
        path: '/serving2',
        exact:true,
        privilege:'campaign',
        title: '项目管理',
        component: CampaignCom
      },
      {
        key:"report",
        path: '/report/optimization',
        exact:true,
        privilege:'report',
        title: '分析报告',
        component: ReportCom,
        newTab:true
      },
      {
        key:"dealId",
        path: '/serving2/deal-id',
        exact:true,
        privilege:'dealId',
        title: 'Deal ID管理'
      },
    ],
  }
])
const reportRouters: RouterType[] = ([
      {
        key:"optimization",
        path: '/report/optimization',
        exact:true,
        privilege:'report_optimization',
        title: '优选报告',
        component: ReportCom,
        newTab:true
      },
      {
        key:"circulation",
        path: '/report/circulation',
        exact:true,
        privilege:'report_circulation',
        title: '投放报告',
        component: ReportCom,
        newTab:true
      },
      {
        key:"frequency",
        path: '/report/frequency',
        exact:true,
        privilege:'report_frequency',
        title: '频次报告',
        component: ReportCom,
        newTab:true
      }
])
export {
  Routers,
  reportRouters
}