import { Reducer } from 'redux';
import { TCurrentUser } from '@/types/user';
import { TBooleanEnum, TUserRoleEnum, TUserStatusEnum } from '@/types/enum';
import { TActionTypeEnum, TGlobalState, TAction } from '@/types/global';
export const getInitUser = (): TCurrentUser => ({
  id: 0,
  name: '',
  role: TUserRoleEnum.MEMBER,
  email: '',
  status: TUserStatusEnum.DISABLED,
  language: '',
  company: '',
  title: '',
  city: '',
  isDelete: TBooleanEnum.NO,
  isInactive: TBooleanEnum.NO,
  createdAt: '',
  updatedAt: '',
  switchs: {},
  networks: {},
  expiredNetworks:{}
});
const GlobalReducer: Reducer<TGlobalState, TAction> = (
  state = {
    currentUser: getInitUser(),
    currentNetworkId: 0,
    currentCampaignId: 0,
    medias:[]
  },
  action: TAction,
) => {
  let newState = state;
  switch (action.type) {
    case TActionTypeEnum.SET_NETWORK_ID: {
      const networkId = Number(action.payload) || 0;
      localStorage['networkId'] = networkId;
      newState = {
        ...state,
        currentNetworkId: networkId,
      };
      break;
    }
    case TActionTypeEnum.SET_CAMPAIGN_ID: {
      localStorage['campaignId'] = action.payload;
      newState = {
        ...state,
        currentCampaignId: Number(action.payload) || 0,
      };
      break;
    }
    case TActionTypeEnum.SET_USER:
      newState = {
        ...state,
        currentUser: action.payload,
      };
      break;
    case TActionTypeEnum.SET_BY_QIANKUN:
      newState = {
        ...state,
        ...action.payload,
      };
      break;
    case TActionTypeEnum.SET_MEDIAS:
      newState = {
        ...state,
        medias:action.payload
      };
      break;
    default:
      break;
  }
  // 如果在master中修改了状态(非 setGlobalState引起的修改),则设置一下全局状态,保持全局状态的一致性
  // if (action.type !== TActionTypeEnum.SET_BY_QIANKUN) {
  //   window[MICRO_APP_INSTANCE]?.actions.setGlobalState(newState);
  // }

  return newState;
};


export default GlobalReducer;
