/**
 * 工具函数
 */
 import CryptoJS from 'crypto-js';

 const permissions:any = {
  'a': 4,
  'w': 2,
  'r': 1,
  '': 0
};
/**
 * 检查权限，传递工权限名称，返回权限值
 * @param permName 权限名称
 * @returns 当前权限
 */
const getNetworkPermission = (permName:string,networks:any,networkId:number) => {
  const currentNetwork = networks.networks&&networks.networks[networkId];
  if(!currentNetwork){

    return '';
  }
  if(!currentNetwork.network||!currentNetwork.network.switchs[permName]){
    return '';
  }
  if(!currentNetwork.networkRole){

    return currentNetwork.network.switchs[permName];
  }
  if(!currentNetwork.networkRole.switchs[permName]){
    return '';
  };
  return currentNetwork.networkRole.switchs[permName];
};
/**
 * 检查权限，传递工权限名称，返回权限值
 * @param permName 权限名称
 * @param needPermValue 需要权限名称
 * @returns 当前权限
 */
export const hasPermission = (permName:string, needPermValue:string,networks:any,networkId:number) => {
  const permValue = getNetworkPermission(permName,networks,networkId);
  return permissions[permValue] >= permissions[needPermValue];
};

/**
 * 加密信息
 * 用于敏感信息的传递,如 密码
 * @param obj 加密的对象
 * @returns 加密后的字符串
 */
 export const encryptInfo = (obj: Record<string, any>) => {
    let encryptedInfo;
    encryptedInfo = JSON.stringify(obj);
    encryptedInfo = CryptoJS.enc.Utf8.parse(encryptedInfo);
    encryptedInfo = CryptoJS.enc.Base64.stringify(encryptedInfo);
    encryptedInfo = CryptoJS.RC4.encrypt(encryptedInfo, 'nequal').toString();
    return encryptedInfo;
  };

export const store = {
  read: (key:any) => localStorage.getItem(key),
  write: (key:any, value:any) => localStorage.setItem(key, value),
  remove: (key:any) => localStorage.removeItem(key)
}
