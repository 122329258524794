/**
 * 展示 [ id ] name
 */
 import { DownOutlined } from '@ant-design/icons';
 export interface TProps {
    id: string | number | undefined;
    name: string;
    defaultName?: string;
    icon?:boolean;
  }
  
  const IdNameComponent = ({ id, name, defaultName ,icon}: TProps) => {
    return (
      <>
        <span className='m-r-xs'>[{id}]</span>
        <span className='idName-name'>{name || defaultName || ''}</span>
        {icon&&<DownOutlined style={{fontSize:'12px',margin:'6px'}} />}
      </>
    );
  };
  
  export default IdNameComponent;
  