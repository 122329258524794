/**
 * 控制登陆权限
 */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
const AuthRoute = ({ component: Component, ...rest }: any) =>
(
  <Route
    {...rest}
    render={props => {
      const isLogin = (localStorage.getItem("access_token") && localStorage.getItem("api_token") && localStorage.getItem("refresh_token"));
      if (isLogin) {
        return <Component {...props} />
      }
      return <Redirect to={{ pathname: '/login' }} />

    }}
  />
)


export default AuthRoute;