import { request } from './request';
export function apiSession() {
    return request({
      method: 'GET',
      url: `/api_v2/session`
    });
  }
export function apiOauth() {
  return request({
    method: 'GET',
    url: `/api_v2/oauth`
  });
}