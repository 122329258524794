import { request } from '@/api/request';
interface SearchParamsData {
    target: string;
    q: string;
  }
export interface ResultData{
    campaignId?: any;
    networkId?: any;
    id:number,
    name:string
}
export function apiSearch(data: SearchParamsData) {
    return request<ResultData>({
      method: 'GET',
      url: `/api_v2/${data.target}/${data.q}`
    });
  }