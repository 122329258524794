/**
 * 用户网络角色权限等级
 */
 export enum TNetworkRoleSwitchLevelEnum {
    ALL = 'a',
    WRITE = 'w',
    READ = 'r',
  }
  
  export enum TUserStatusEnum {
    ENABLED = 'enabled',
    DISABLED = 'disabled',
  }
  
  export enum TBooleanEnum {
    YES = 'yes',
    NO = 'no',
  }
  
  export enum TUserRoleEnum {
    ADMIN = 'admin',
    MEMBER = 'member',
  }
  
  export enum TDealIdChargeModeEnum {
    CPM = 'cpm',
    CPD = 'cpd',
  }
  
  /**
   * 用户网络角色权限模块名称
   */
  export enum TNetworkRoleSwitchNameEnum {
    CAMPAIGN = 'campaign',
    REPORT = 'report',
    REPORT_CIR = 'report_circulation',
    REPORT_FREQ = 'report_frequency',
    DATABANK = 'databank',
    USER = 'user',
    TOOLS = 'tools',
    CAMPAIGN_ADSERVING = 'campaign_adServing',
    REPORT_OPT = 'report_optimization',
    TOOLS_IMPORT_PACKAGE = 'tools_importPackage',
    OPERATING = 'operating',
    OPERATING_REALTIME = 'operating_realtime',
    OPERATING_DATABANK = 'operating_databank',
    OPERATING_PEOPLE_PACKAGE = 'operating_peoplePackage',
    OPERATING_M_PLATFORM_DMP = 'operating_mPlatformDMP',
    OPERATING_CAMP_PKG = 'operating_campPkg',
    DEAL_ID = 'dealId',
  }
  
  // 通用状态
  export enum TCommonStatusEnum {
    WAITING = 'waiting',
    DONE = 'done',
    DOING = 'doing',
    PAUSED = 'paused',
  }
  