/**
 * 所有对接的媒体ID
 * 只有在这里定义的媒体才会展示
 */

 import {getEnumValues} from '@/utils/enum';
 export enum TMediaIdEnum {
  TENCENT_TV_ID = 2083  , //  腾讯视频
  YOUKU_ID =  407   , //   优酷
  SOHU_VIDEO_ID = 1849  , //   搜狐视频
  SOHU_WEB_ID=  124   , //   搜狐网
  SOHU_WAP_ID=  2137  , //   搜狐WAP
  SOHU_APP_ID =   2743  , //   搜狐新闻APP
  LESHI_ID =  2751  , //   乐视TV
  MGTV_ID =  1370  , //   芒果TV
  PPTV_ID =  1113  , //   PPTV
  PPTV_SPROT_ID =  4545  , //   PPTV-体育
  BF_VIDEO_ID=  1114  , //   暴风影音
  FUN_ID=  1238  , //   风行
  FUN_OTT_ID=  3928  , //   风行OTT
  IQI_ID=  1118  , //   爱奇艺
  PCLADY_ID=  232   , //   Pclady
  ONLYLADY_ID=  234   , //   onlylady
  RAYLI_ID=  402   , //   Rayli
  VOGUE_ID=  1206  , //   Vogue
  QUNA_ID=  1511  , //   去哪儿网
  MTY_ID=  3343  , //   Tripadvisor猫途鹰
  XL_I=  1357  , //   迅雷看看
  YUEYE_ID=  1574  , //   越野e族
  TENCENT_NEWS_ID=  2209  , //   腾讯新闻客户端
  CE_CONLINE_ID=  3900  , //   CEConline
  TECENT_WEB_ID =  440   , //   腾讯网
  TOUTIAO_ID=  2343  , //   今日头条
  YOUKA_ID=  266   , //   优卡时尚
  TAIPINGYANG_ID=  71    , //   太平洋汽车网
  YICHE_ID=  151   , //   易车网
  YOUTU_ID=  1646  , //   优土
  WEIBO_ID=  2261  , //   新浪微博-M
  TENCENT_KUAIBAO_ID=  3492  , //   天天快报
  TENCENT_SPORT_ID=  4143  , //   腾讯体育
  TENCENT_QQ_BROSWER_ID=  3083  , //   QQ浏览器-M
  TENCENT_QQ_ZONE_ID =  4765  , //   QQ空间
  TENCENT_QQ_ID =  4766  , //   手机QQ
  TENCENT_KG_ID =  4611  , //   全民K歌
  TENCENT_KUWO_ID=  1069  , //   酷我音乐
  TENCENT_QQ_MUSIC_ID =  2101  , //   QQ音乐
  TENCENT_MOJI_ID =  1982  , //   墨迹天气
  TOUTIAO_XIGUA_ID =  4186  , //   西瓜视频
  TOUTIAO_DOUYIN_ID =  4330  , //   抖音
  TOUTIAO_HUOSHAN_ID =  4360  , //   火山小视频
  WIFI_ID= 3329  , //   wifi万能钥匙
  MIGU_ID =   3352  , //   咪咕视频
  UC_ID =  1519  , //   UC
  UC_V2_ID =  4790  , //   UC-V2
  VIVO_ID =  4452  , //   Vivo
  CHUANGWEI_ID =  3069  , //   创维
  WANGYI_NEWS_ID =  3078  , //   网易新闻
  PEARVIDEO_ID =  4063  , //   梨视频
  ADTALOS_ID =   4744  , //   新义互联
  BAIDU_ID =  4210  , //   百度信息流
  DIANSHIMAO_ID=  4745  , //   电视猫
  MEIREN_ID=  3064  , //   美人相机
  MI_ID=  4190  , //   小米
  BAIDU_APP_ID =  4522  , //   手机百度app
  ZHIHU_ID=  1883  , //   知乎
  XIMALAYA_ID=  2946  , //   喜马拉雅
  OPPO_ID=  3123  , //   oppo
  QUTOUTIAO_ID=  4183  , //   趣头条
  LEBO_ID =  4046  , //   乐播
  WANGYI_NEX_ID =  4764  , //   网易新闻-NEX
  XTRADER_ID =  3251  , //   灵集
  WANGYI_EMAIL_ID=  4767  , //   网易邮箱大师-NEX
  FANCY_ID=  4768  , //   Fancy SSP
  TENGCENT_PTU_ID=  3477  , //   天天P图
  TENGCENT_EGAME_ID=  4773  , //   企鹅电竞
  TENGCENT_READ_ID=  4774  , //   QQ阅读
  WANGYI_YOUDAO_ID=  4775  , //   网易有道
  TENCENT_KUGOU_ID=  4776  , //   酷狗音乐
  MEISHU_ID=  4779  , //   美数ssp
  TOUTIAO_LIANPING_ID=  4780  , //   头条系_联屏开屏
  WANGYI_MUSIC_ID=  3465  , //   网易云音乐
  INMOBI_ID=  1572  , //   InMobi
  HAIXIN_ID=  4782  , //   海信
  IREADER_ID=  4298  , //   掌阅
  FEILIPU_ID=  4224  , //   飞利浦
  YITONG_ID=  3070  , //   壹通传媒
  HUAWEI_ID=  3308  , //   华为
  BAIDU_MAP_ID=  3475  , //   百度地图
  TENCENT_INFO_ID=  4787  , //   腾讯系_信息流联投
  HUANWANG_ID=  3325  , //   欢网
  M1_ID=  4788  , //   M1 Activator SSP
  KUAISHOU_ID=  4068  , //   快手
  DOUYU_ID=  2957  , //   斗鱼
  TENGCENT_FM_ID=  4263  , //   企鹅电竞
  HYSSP_ID=  4789  , //   HYSSP
  TENGCENT_MANHUA_ID=  4097  , //   快看漫画
  KEEP_ID=  3970  , //   keep
  QINGTING_FM_ID=  2826  , //   蜻蜓FM
  HUYA_ID=  3224  , //   虎牙
  MEITU_ID=  1645  , //   美图秀秀
  MEIYAN_ID=  4410  , //   美颜相机
  WANGWANG_SSP_ID=  4792  , //   旺脉SSP
  ONE_OF_SSP_ID=  4793  , //   one of ssp
  SOUL_ID=  4794  , //   soul
  YIDIANZIXUN_ID=  3149  , //   一点资讯
  BAIDU_TIEBA_ID=  2063  , //   百度贴吧
  MAIMAI_ID=  4152  , //   脉脉
  KUGOU_MUSIC_ID=  4798  , //   酷狗音乐_腾讯系
  TENGXUN_WEISHI_ID=  4012  , //   腾讯微视
  DIDA_ID=  4799  , //   嘀嗒出行
  MIGU_OTT_ID=  4800  , //  咪咕视频-OTT
  KANGJIA_ID=  4801  , //  康佳
  DOUGGUO_ID=  2738  , //  豆果美食
  HAOKAN_ID=  4802  , //  好看视频
  MEIYOU_ID=  2986  , //  美柚
  YUEDONGQUAN_ID=  3467  , //  悦动圈
  BILIBILI_ID=  4783  , //   b站
  TENGCENT_SHOUJI_ID=  4809  , //   腾讯手机管家
  LERONG_ID=  4810  , //   乐融
  CHAOJIKECHENGBIAO_ID=  3366  , //   乐融
  CUITINDSSP_ID=  4812  , //   萃汀SSP

 }
 
 export const ALL_MEDIA_IDS = getEnumValues(TMediaIdEnum);
 