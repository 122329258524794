/**
 * 全局搜索
 */
import React, { useCallback, useState } from 'react';
import { Popover, Select, Form, List, Input, Empty, PopoverProps } from 'antd';
import {useDispatch } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { InputProps } from 'antd/es/input';
import { TActionTypeEnum } from '@/types/global';
import { map, pick } from 'lodash';
import { TResultItem as TResultItemType } from './type';
import { apiSearch, ResultData } from './services';
import './style.less';
import noData from '@/assets/img/noData.png';
import useGlobalState from '@/hooks/useGlobalState';

const ListItem = List.Item;

//初始化select中的option
const SearchOptions: {
  label: string;
  value: string;
  title: string;
  route: (result: TResultItemType) => string;
}[] = [
    {
      label: '项目ID',
      value: 'campaigns',
      title: '项目',
      route: (result) => `#!/campaign/${result.id}/placement`, 
    },
    {
      label: '广告位ID',
      value: 'placements',
      title: '广告位',
      route: (result) => `#!/campaign/${result.campaignId}/placement?placementId=${result.id}`,
    },
    {
      label: '广告ID',
      value: 'advertisements',
      title: '广告',
      route: (result) => `#!/campaign/${result.campaignId}/advertisements/${result.id}`,
    },
    {
      label: '物料ID',
      value: 'creatives',
      title: '物料',
      route: (result) => `#!/campaign/${result.campaignId}/material?materialId=${result.id}`,
    },
    {
      label: 'DealId',
      value: 'placements/dealIds',
      title: '广告位',
      route: (result) => `#!/campaign/${result.campaignId}/placement?placementId=${result.id}`,
    },
  ];

const HeadSearch = () => {
  const [form] = useForm();
  const [results, setResults] = useState<TResultItemType[]>([]);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const {setCurrentNetworkId } = useGlobalState();
  const getActiveRule = (pattern: string) => {
    console.log('window.location.pathname',window.location.pathname)
    return window.location.pathname=== pattern;
  }
  //获取根节点
  const getRootDom = ()=>{
    return document.getElementById('master-root') || document.body
  }
  //设置下拉框
  const addonBefore = (
    <Form.Item name='target'  noStyle initialValue={SearchOptions[0].value}>
      <Select 
        getPopupContainer={triggerNode => triggerNode.parentNode}
        options={SearchOptions.map((option) => pick(option, ['label', 'value']))}
      />
    </Form.Item>
  );
  const hide = () => {
    setVisible(false)
  };
  //处理弹出框展示
  const handleVisibleChange: PopoverProps['onVisibleChange'] = (v) => {
    if (!v) {
      setVisible(false);
    } else {
      setResults([]);
    }
  };
  //搜索
  const onkeydown: InputProps['onKeyDown'] = useCallback(async (e) => {
    if (!e.target.value||e.keyCode!==13) return;
    // const patrn = /^[0-9A-z]*$/ ;
    // if (!patrn.test(e.target.value)) return message.error('请输入正确的 ID。');
    console.log(e.target.value)
    if(e.target.value[e.target.value.length-1]===','){
      const str = e.target.value.slice(0,-1)
      form.setFieldsValue({q:str})
    }
    form.submit();
  }, [form])

  //校验是不是数组返回不同的值
  const isArray = (data: any[] | ResultData) =>{
    if(Array.isArray(data)){
      return data[0];
    }else{
      return data;
    }
  }

  const handleSearchChange = useCallback(async (key:string,data:ResultData)=>{
    hide();
    dispatch({
      type: TActionTypeEnum.SET_CAMPAIGN_ID,
      payload: isArray(data).id,
    });
    if(form.getFieldValue('target')==='campaigns'){
      setCurrentNetworkId(isArray(data).networkId);
    }else{
     await apiSearch({ 
        target: 'campaigns',
        q:  isArray(data).campaignId
      }).then(result => {
          setCurrentNetworkId(isArray(result.data).networkId);
      })
    }
    form.setFieldsValue({
      q:''
    });
    if (getActiveRule("/serving")) {
      window.location.hash = key
    }else {
      window.history.pushState(null, '', `/serving${key}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  //设置搜索弹出框展示的内容
  const EmptyContent = visible && <Empty image={<img src={noData} alt='' />} description='不存在或没有权限' />;
  const Content = () => {
    const option = SearchOptions.find((op) => op.value === form.getFieldValue('target'));
    if (results.length>1) {
      return (
        <List size='small' className='' header={option?.title}>
          {map(results, (result) => {
            let tempRoute = option?.route(result)|| '';
             return (
              <ListItem key={result.id}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={()=>{handleSearchChange(tempRoute,result)}}>
                [{result.id}] {result.name}
              </a>
            </ListItem>
             )
          })}
        </List>
      );
    }
      return EmptyContent;
  };

  //获取表单数据发起请求
  const onFinish = useCallback(async (values) => {

    await apiSearch(values).then( async res => {
      console.log("res",res)
      if (Array.isArray(res.data)&&res.data.length>1) {
          setResults(res.data);
          setVisible(true);
      } else if ((res && res.data.id && res.data.name)||(Array.isArray(res.data)&&res.data.length===1)) {
        const option = SearchOptions.filter((op) => op.value === form.getFieldValue('target'))[0];
        let tempRoute = Array.isArray(res.data)?option.route(res.data[0]):option.route(res.data)|| '';
        handleSearchChange(tempRoute,res.data);
      }else if(Array.isArray(res.data)&&res.data.length=== 0){
        setResults([]);
        setVisible(true);
      }
    }).catch(err => {
      setResults([]);
      setVisible(true);
      console.log("err", err)
    })
  }, [form, handleSearchChange]);

  return (
    <Popover
      id="main-popover"
      placement='bottomRight'
      content={Content}
      visible={visible}
      onVisibleChange={handleVisibleChange}
      getPopupContainer={()=> getRootDom()}
      trigger='click'
      
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item name='q' noStyle>
          <Input
            addonBefore={addonBefore}
            autoComplete='off'
            placeholder='请输入项目ID'
            allowClear
            onKeyDown={onkeydown}
          />
        </Form.Item>
      </Form>
    </Popover>
  );
};

export default HeadSearch;
