import { request } from './request';
export function apiMedias() {
    return request({
      method: 'GET',
      url: `/api_v2/medias`,
      params: {
        startIndex: 0,
        maxResults: 5000
      }
    });
  }