export interface Config {
  BASENAME:'/',
  TITLE:string,
  API_URL:string,
  SUB_APP_CONTAINER:string
  LOGIN_EXPIRE: number;
  SUCCESS_CODE:number;
  TOTAL_PADDING_TOP:string;
}

const GlobalConfig: Config = {
  BASENAME: '/', //根路径
  TITLE: 'Smart serving',//title
  API_URL: '/',//接口根地址
  SUB_APP_CONTAINER:'subappView',//子应用容器
  LOGIN_EXPIRE: 400,
  SUCCESS_CODE:0 || 200,
  TOTAL_PADDING_TOP:"64px",

};

export default GlobalConfig;
// 微应用实例
export const MICRO_APP_INSTANCE = Symbol('micro app instance');

