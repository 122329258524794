import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { message} from 'antd';
import GlobalConfig from '@/config/index';

interface ResponseData<T> {
  status: number;
  data: T;
  statusText: string;
  message?:string
}

// 指定 axios 请求类型

axios.defaults.headers = {
  'Content-Type': 'application/json;charset=utf-8',
};

// 指定请求地址

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? GlobalConfig.API_URL : '';

// 添加请求拦截器
axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('api_token')||'';
    const user_number = localStorage.getItem('user_number') ||''
    if (token) {
      if(config && config.headers){
        config.headers['X-Auth-Token'] = token;
        config.headers['User-Number'] = user_number;
      }
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

// 添加响应拦截器，拦截登录过期或者没有权限

axios.interceptors.response.use(
  (response: AxiosResponse<ResponseData<any>>) => {
    if (!response.data) {
      return Promise.resolve(response);
    }
    // 请求成功
    if (response.statusText === 'OK') {
      return response as any;
    }
    // 请求成功
    if (response.status === 200) {
      return response as any;
    }
    // 请求成功，状态不为成功时
    message.error("请求失败");
    return Promise.reject(new Error("请求失败"));
  },
  (error: AxiosError) => {
    error&&error.code&&message.error(error.code);
    return Promise.reject(error);
  },
);

// 统一发起请求的函数
export function request<T>(options: AxiosRequestConfig) {
  return axios.request<T>(options);
}
