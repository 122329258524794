/*
 * @Description: 
 * @Author: jiangdongdong@mininglamp.com
 * @Date: 2023-11-03 16:35:09
 */
import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Routers } from '@/routers';
import NotFound from '@/pages/None';
import Login from '@/pages/Login';
import AuthRoute from '@/components/AuthRoute';
const renderRoutes = (routes: any) => {
    if (!Array.isArray(routes)) {
        return null;
    }
    return (
        <Switch>
            <Route exact path="/login" component={Login} />
            {routes.map((router, index) => {
                if (router.redirect) {
                    return (
                        <Redirect
                            key={router.path || index}
                            exact={router.exact}
                            from={router.path}
                            to={router.redirect}
                        />
                    );
                }
                return (
                    <AuthRoute
                        exact={router.exact}
                        key={router.path}
                        path={router.path}
                        component={() => {
                            const renderChildRoutes = renderRoutes(router.childRoutes);
                            if (router.component) {
                                return (
                                    <Suspense fallback={<div>加载中..</div>}>
                                        <router.component route={router}>{renderChildRoutes}</router.component>
                                    </Suspense>
                                );
                            }
                            return renderChildRoutes;
                        }}
                    />
                );
            })}
            <Route path="*" component={NotFound} />
        </Switch>
    );
};
const AppRouter = () => {
    return <Router>{renderRoutes(Routers)}</Router>
};
export default AppRouter