import _ from 'lodash';
import { useEffect, useState } from 'react';
import {useDispatch } from 'react-redux';
import { apiMedias } from '@/api/medias';
import { TMediaModel } from '@/types/media';
import {ALL_MEDIA_IDS} from '@/data/media-id';
import { TActionTypeEnum } from '@/types/global';
interface TProps {
    isLogin?: boolean;
  }
interface MediaModelArray {
    [index: number]: TMediaModel
}
  
const useInitMedia = ({ isLogin }: TProps) => {
    const [mediaList, setMediaList] = useState<MediaModelArray>([]);
    const dispatch = useDispatch();
    useEffect(() => {
      if (!isLogin) return;
      (async () => {
        const res = await apiMedias();
        setMediaList(res.data as MediaModelArray)
      })();
    }, [isLogin]);
  
    useEffect(() => {
      if(mediaList && mediaList[0]){
        // 媒体列表
      const usingMedias = _.filter(mediaList,(media) => ALL_MEDIA_IDS.includes(media.id));
      const fUsingMedias = _.map(usingMedias,(media) => _.pick(media, ['id', 'name_cn']));
      localStorage['medias']= JSON.stringify(fUsingMedias);
      dispatch({
        type: TActionTypeEnum.SET_MEDIAS,
        payload: fUsingMedias,
    });
      }
      
    }, [dispatch, mediaList]);
  };
  
  export default useInitMedia;
  