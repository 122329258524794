import { request } from '@/api/request';
import { encryptInfo } from '@/utils/utils';
interface DataType {
  access_token: string;
  api_token: string | undefined;
  refresh_token: string;
  user_number: number;
  role: number;
}

export interface UserState {
  data: DataType
}

export interface UserLoginData {
  username: string;
  password: string;
}

export function apiCampaignList(data: UserLoginData) {
  return request<UserState>({
    method: 'POST',
    url: '/api_v2/sign/signin',
    data: {
      encryptedInfo: encryptInfo(data)
    },
  });
}
