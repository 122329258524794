import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import App from '@/App';
import store from '@/store';
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider prefixCls="mainPre">
    <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('master-root')
)

