/*
 * @Description: 
 * @Author: jiangdongdong@mininglamp.com
 * @Date: 2022-05-22 12:25:36
 */
/**
 * 底部
 */
import React from 'react';
import { Layout } from 'antd';
import './style.less'
const {Footer} = Layout;
const MainFooter = () => (
  <Footer className='text-center main-footer'> 
    {' '}
    <span>© Copyright Mininglamp Technology Released: </span>
    <a target='_blank' href='https://beian.bizcn.com' rel="noreferrer">
      京ICP备16022188号-3
    </a>
  </Footer>
);

export default MainFooter;
