/*
 * @Description: 
 * @Author: jiangdongdong@mininglamp.com
 * @Date: 2023-11-03 16:35:09
 */
/**
 * 基本布局
 */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Layout } from 'antd';
import MicroApp from '@/config/micro-app';
import useGlobalState from '@/hooks/useGlobalState';
import useInitMedia from '@/hooks/useInitMedia';
import { MICRO_APP_INSTANCE } from '@/config/index';
import { TActionTypeEnum,TGlobalState } from '@/types/global';
import '@/styles/index.less';
import MainHeader from './MainHeader';
import MainFooter from "./MainFooter";
const { Content } = Layout;

const BasicLayout = ({ route, children }: any) => {
  const dispatch = useDispatch();
  const { isLogin, currentUser, currentNetworkId, currentCampaignId, getSession ,setCurrentNetworkId} = useGlobalState();
  //获取用户基本信息
  useEffect(() => {
    getSession();
  }, [getSession]);
  // 初始化media
  useInitMedia({ isLogin });
  //注册并启动子应用
  useEffect(() => {
    if (!isLogin || !currentNetworkId) return;
    // 已经注册过了,直接返回
    if (window[MICRO_APP_INSTANCE]){
      console.log("已经注册了")
      const state = { currentNetworkId: currentNetworkId, currentUser: currentUser, currentCampaignId: currentCampaignId ,setCurrentNetworkId};
      console.log('state',state);
      window[MICRO_APP_INSTANCE]?.actions.setGlobalState(state)
      return;
    };
    console.log("重新注册",currentNetworkId)
    const microApp = new MicroApp({
      host: window.location.host,
      state: { currentNetworkId: currentNetworkId, currentUser: currentUser, currentCampaignId: currentCampaignId ,setCurrentNetworkId},
    });
    window[MICRO_APP_INSTANCE] = microApp;
    microApp.register();
    microApp.start();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, currentNetworkId,currentCampaignId])

  // 监听由子应用触发的状态改变
  useEffect(()=>{
    if(!window[MICRO_APP_INSTANCE])return;
    window[MICRO_APP_INSTANCE]?.actions.onGlobalStateChange((newState: TGlobalState) => {
      // 修改主应用状态
      dispatch({
        type: TActionTypeEnum.SET_BY_QIANKUN,
        payload: newState as TGlobalState,
      });
    });
  },[dispatch]);

  return (
    <Layout style={{ minHeight: '100vh',paddingTop: '58px' }}>
      <MainHeader routes={route.childRoutes} />
      <div id="subappView">
        <Content>
          {children}
        </Content>
      </div>

      <MainFooter />
    </Layout>
  )
};

export default BasicLayout;